import provideState_P8FnVaBoM5 from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.1.0_@parcel+watcher@2.4.1_@types+node@22.2.0_graphql-tag@2.12.6_gra_cqs6ndm4gtdlapkgia6epy25cy/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_Qwk9fL165W from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ouj2WRoFv9 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_G9BfgL7yOs from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_F1zDHMXtoQ from "/usr/local/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.3_6qfcrpfssslpo55bczjq6o4bs4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_A80tTgnXzB from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WwY9VggH9U from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VMOxYp0N80 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_P3CQ8H5DVe from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_2isgDIWVZi from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__7jsev552lwyoxdi56kxiuczdde/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_kMFpnxgd3E from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_974SCkyh2f from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+no_q7prav3l6md3thabngrjeff4fq/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_O02RfJjQO6 from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_LwzXRlA7mD from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_et1e97p5jW from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_yGJPLcpnb3 from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import history_client_90Xi9yF6BH from "/usr/local/app/plugins/history.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  provideState_P8FnVaBoM5,
  revive_payload_client_Qwk9fL165W,
  unhead_ouj2WRoFv9,
  router_G9BfgL7yOs,
  _0_siteConfig_F1zDHMXtoQ,
  payload_client_A80tTgnXzB,
  navigation_repaint_client_WwY9VggH9U,
  check_outdated_build_client_VMOxYp0N80,
  chunk_reload_client_P3CQ8H5DVe,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2isgDIWVZi,
  plugin_client_kMFpnxgd3E,
  documentDriven_974SCkyh2f,
  nuxt_plugin_O02RfJjQO6,
  plugin_LwzXRlA7mD,
  i18n_et1e97p5jW,
  plugin_yGJPLcpnb3,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  history_client_90Xi9yF6BH,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]