<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

useHead({
  htmlAttrs: {
    lang: i18nHead.value?.htmlAttrs?.lang ?? 'en',
    dir: i18nHead.value?.htmlAttrs?.dir ?? 'ltr',
    class: 'scroll-smooth xs:text-[16px] text-[14px]',
  },
  bodyAttrs: {
    class: 'font-sans antialiased',
  },
  title: computed(() => t('layouts.title', {
    title: t(route.meta?.title as string | undefined ?? 'pages.title.index'),
  })),
  meta: [
    ...(i18nHead.value.meta || []),
    ...(useRuntimeConfig().public.facebookDomainVerification ? [{ name: 'facebook-domain-verification', content: useRuntimeConfig().public.facebookDomainVerification }] : []),
    ...(useRuntimeConfig().public.googleSiteVerification ? [{ name: 'google-site-verification', content: useRuntimeConfig().public.googleSiteVerification }] : []),
    { name: 'viewport', content: 'width=device-width,initial-scale=1,viewport-fit=cover' },
    { name: 'charset', content: 'UTF-8' },
    { name: 'description', content: 'description' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent' },
    { property: 'og:image', content: '/image.png' },
    { name: 'description', content: 'UZO · Shop & Save' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: '/image.png' },
  ],
  link: [
    ...(i18nHead.value.link || []),
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico', sizes: 'any' },
    { rel: 'icon', type: 'image/png', href: '/icon.png', sizes: 'any' },
    { rel: 'icon', type: 'image/svg+xml', href: '/logo.svg', sizes: 'any' },
    { rel: 'apple-touch-icon', type: 'image/png', href: '/apple-touch-icon.png', sizes: 'any' },
    // { rel: 'preload', href: '/fonts/averta-bold.woff2', as: 'font', type: 'font/woff2', crossorigin: 'anonymous' },
    // { rel: 'preload', href: '/fonts/averta-semi-bold.woff2', as: 'font', type: 'font/woff2', crossorigin: 'anonymous' },
    // { rel: 'preload', href: '/fonts/averta-regular.woff2', as: 'font', type: 'font/woff2', crossorigin: 'anonymous' },
  ],
})
</script>

<template>
  <RdxConfigProvider>
    <NuxtLoadingIndicator color="conic-gradient(at top right,#F43F5E 0%,#EF4444 50%,#F97316 100%)" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </RdxConfigProvider>
</template>
