import { default as indexZRmv4rwJvqMeta } from "/usr/local/app/pages/index.vue?macro=true";
import { default as _reviewsJiXf6xlXcFMeta } from "/usr/local/app/pages/_reviews.vue?macro=true";
import { default as _91_46_46_46slug_93ehlUViFFkaMeta } from "/usr/local/app/pages/[...slug].vue?macro=true";
import { default as indexbcW1kPi65kMeta } from "/usr/local/app/pages/account/addresses/index.vue?macro=true";
import { default as _91id_93eKVSoMbmknMeta } from "/usr/local/app/pages/account/disputes/[id].vue?macro=true";
import { default as indexmupPhdpzHJMeta } from "/usr/local/app/pages/account/disputes/index.vue?macro=true";
import { default as indexf0kufjSeQTMeta } from "/usr/local/app/pages/account/index.vue?macro=true";
import { default as _91code_93Z5gRwhAl1xMeta } from "/usr/local/app/pages/account/orders/[code].vue?macro=true";
import { default as indexyavSc54tHJMeta } from "/usr/local/app/pages/account/orders/index.vue?macro=true";
import { default as securityGIscjYEtcLMeta } from "/usr/local/app/pages/account/security.vue?macro=true";
import { default as accountkzLoSvJwtmMeta } from "/usr/local/app/pages/account.vue?macro=true";
import { default as change_45emailUR6mw1en5xMeta } from "/usr/local/app/pages/auth/change-email.vue?macro=true";
import { default as loginF3gIq4xjsbMeta } from "/usr/local/app/pages/auth/login.vue?macro=true";
import { default as logoutgfTlJJpOwXMeta } from "/usr/local/app/pages/auth/logout.vue?macro=true";
import { default as new_45passwordHKAqxDljR1Meta } from "/usr/local/app/pages/auth/new-password.vue?macro=true";
import { default as registerD1YI4k4ySdMeta } from "/usr/local/app/pages/auth/register.vue?macro=true";
import { default as reset_45passwordjZT5RCzmJ4Meta } from "/usr/local/app/pages/auth/reset-password.vue?macro=true";
import { default as verify_45emailShAT883FSKMeta } from "/usr/local/app/pages/auth/verify-email.vue?macro=true";
import { default as _91code_93n8bQXXLUreMeta } from "/usr/local/app/pages/checkout/confirmation/[code].vue?macro=true";
import { default as indexS4P2YxqBHcMeta } from "/usr/local/app/pages/checkout/index.vue?macro=true";
import { default as _91slug_939CT60eHGEsMeta } from "/usr/local/app/pages/collections/[id]/[slug].vue?macro=true";
import { default as best_45sellersm0l9OPhkuGMeta } from "/usr/local/app/pages/collections/best-sellers.vue?macro=true";
import { default as new_45arrivalsOthMm8zd5xMeta } from "/usr/local/app/pages/collections/new-arrivals.vue?macro=true";
import { default as commitmentsPspvuWFo6MMeta } from "/usr/local/app/pages/commitments.vue?macro=true";
import { default as _91_91slug_93_939lFgEk0npoMeta } from "/usr/local/app/pages/products/[id]/[[slug]].vue?macro=true";
import { default as searchYhxkQS4hULMeta } from "/usr/local/app/pages/search.vue?macro=true";
import { default as _91slug_93Wy0ZMWlk3DMeta } from "/usr/local/app/pages/stores/[id]/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___cs",
    path: "/cs/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___en",
    path: "/en/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___hu",
    path: "/hu/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___pl",
    path: "/pl/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___ro",
    path: "/ro/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "_reviews___sk",
    path: "/sk/_reviews",
    component: () => import("/usr/local/app/pages/_reviews.vue").then(m => m.default || m)
  },
  {
    name: "slug___cs",
    path: "/cs/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___hu",
    path: "/hu/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ro",
    path: "/ro/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___sk",
    path: "/sk/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/cs/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___cs",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___cs",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___cs",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___cs",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___cs",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___cs",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___cs",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/en/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___en",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___en",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___en",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___en",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/hu/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___hu",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___hu",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___hu",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hu",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___hu",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___hu",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___hu",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/pl/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___pl",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___pl",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___pl",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pl",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___pl",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___pl",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___pl",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/ro/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___ro",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___ro",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___ro",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ro",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___ro",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ro",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___ro",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/sk/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___sk",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-disputes-id___sk",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-disputes___sk",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sk",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___sk",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___sk",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___sk",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-change-email___cs",
    path: "/cs/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___en",
    path: "/en/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___hu",
    path: "/hu/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___pl",
    path: "/pl/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___ro",
    path: "/ro/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___sk",
    path: "/sk/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___cs",
    path: "/cs/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___hu",
    path: "/hu/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___pl",
    path: "/pl/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___ro",
    path: "/ro/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___sk",
    path: "/sk/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___cs",
    path: "/cs/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___en",
    path: "/en/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___hu",
    path: "/hu/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___pl",
    path: "/pl/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___ro",
    path: "/ro/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___sk",
    path: "/sk/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___cs",
    path: "/cs/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___en",
    path: "/en/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___hu",
    path: "/hu/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___pl",
    path: "/pl/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___ro",
    path: "/ro/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___sk",
    path: "/sk/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___cs",
    path: "/cs/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___en",
    path: "/en/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___hu",
    path: "/hu/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___pl",
    path: "/pl/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___ro",
    path: "/ro/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___sk",
    path: "/sk/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___cs",
    path: "/cs/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___hu",
    path: "/hu/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___pl",
    path: "/pl/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___ro",
    path: "/ro/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___sk",
    path: "/sk/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___cs",
    path: "/cs/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___en",
    path: "/en/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___hu",
    path: "/hu/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___pl",
    path: "/pl/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___ro",
    path: "/ro/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email___sk",
    path: "/sk/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___cs",
    path: "/cs/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___en",
    path: "/en/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___hu",
    path: "/hu/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___pl",
    path: "/pl/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___ro",
    path: "/ro/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___sk",
    path: "/sk/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout___cs",
    path: "/cs/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___hu",
    path: "/hu/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ro",
    path: "/ro/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___sk",
    path: "/sk/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___cs",
    path: "/cs/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___en",
    path: "/en/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___hu",
    path: "/hu/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___pl",
    path: "/pl/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___ro",
    path: "/ro/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-id-slug___sk",
    path: "/sk/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___cs",
    path: "/cs/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___en",
    path: "/en/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___hu",
    path: "/hu/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___pl",
    path: "/pl/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___ro",
    path: "/ro/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-best-sellers___sk",
    path: "/sk/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___cs",
    path: "/cs/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___en",
    path: "/en/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___hu",
    path: "/hu/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___pl",
    path: "/pl/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___ro",
    path: "/ro/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "collections-new-arrivals___sk",
    path: "/sk/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "commitments___cs",
    path: "/cs/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "commitments___en",
    path: "/en/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "commitments___hu",
    path: "/hu/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "commitments___pl",
    path: "/pl/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "commitments___ro",
    path: "/ro/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "commitments___sk",
    path: "/sk/commitments",
    component: () => import("/usr/local/app/pages/commitments.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro",
    path: "/ro",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/sk",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___cs",
    path: "/cs/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___en",
    path: "/en/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___hu",
    path: "/hu/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___pl",
    path: "/pl/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___ro",
    path: "/ro/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___sk",
    path: "/sk/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "search___cs",
    path: "/cs/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___hu",
    path: "/hu/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___ro",
    path: "/ro/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___sk",
    path: "/sk/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___cs",
    path: "/cs/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___en",
    path: "/en/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___hu",
    path: "/hu/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___pl",
    path: "/pl/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___ro",
    path: "/ro/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___sk",
    path: "/sk/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  }
]